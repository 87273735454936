var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-container"},[_c('div',{staticClass:"view"},[_c('div',{staticClass:"view-body"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table","loading":_vm.loading && !_vm.formLoaded}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"footer-props":_vm.dataTableOptions.footerProps,"options":_vm.dataTableOptions,"server-items-length":_vm.dataTableOptions.totalItems,"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('system.contractors_plans'))+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('SimpleStaticFilters',{attrs:{"filter-params":_vm.filterParams},on:{"setFilters":_vm.setFilters}})],1)]},proxy:true},{key:"item.target_expected",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mt-1 mr-1",staticStyle:{"position":"relative","top":"-3px"},attrs:{"color":"secondary","x-small":"","icon":"","text":""},on:{"click":function($event){return _vm.saveTarget(item)}}},[(!item.targetEdit)?_c('v-icon',{attrs:{"size":"14"}},[_vm._v("fas fa-edit ")]):_c('v-icon',{attrs:{"size":"14"}},[_vm._v("fas fa-check ")])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.target_expected),expression:"item.target_expected"}],staticClass:"pl-1 pr-1",class:{'edit-input': item.targetEdit},staticStyle:{"width":"70px"},attrs:{"readonly":!item.targetEdit,"type":"number","title":_vm.$t('system.double_click_to_edit')},domProps:{"value":(item.target_expected)},on:{"dblclick":function($event){item.targetEdit = true},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveTarget(item)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }item.targetEdit = false}],"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "target_expected", $event.target.value)}}})]}},{key:"item.last_transaction_status",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.last_transaction_status))])]}},{key:"item.is_new",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.is_new ? 'success' : 'secondary'}},[_vm._v(_vm._s(item.is_new ? _vm.$t('system.yes') : _vm.$t('system.no')))])]}},{key:"item.visit_status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.visit_status ? 'green' : 'red'}},[_vm._v("fas fa-car")])]}},{key:"item.account_priority_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.account_priority_color,"dark":""}},[_vm._v(" "+_vm._s(item.account_priority_name)+" ")])]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('system.no_data'))+" ")]},proxy:true}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }