<template>
  <div class="view-container">
    <div
        class="view"
    >
      <div class="view-body">
        <v-skeleton-loader
            class="mx-auto"
            type="table"
            :loading="loading && !formLoaded"
        >
          <v-data-table
              :footer-props="dataTableOptions.footerProps"
              :options.sync="dataTableOptions"
              :server-items-length="dataTableOptions.totalItems"
              :headers="headers"
              :items="data"
              :loading="loading"
              class="elevation-0"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('system.contractors_plans') }}
                </v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                />
                <v-spacer />
                <SimpleStaticFilters
                    :filter-params="filterParams"
                    @setFilters="setFilters"
                />
              </v-toolbar>
            </template>
            <template v-slot:item.target_expected="{ item }">
              <v-btn
                  class="mt-1 mr-1"
                  color="secondary"
                  style="position:relative; top: -3px"
                  x-small
                  icon
                  text
                  @click="saveTarget(item)"
              >
                <v-icon
                    v-if="!item.targetEdit"
                    size="14"
                >fas fa-edit
                </v-icon>
                <v-icon
                    v-else
                    size="14"
                >fas fa-check
                </v-icon>
              </v-btn>
              <input
                  @dblclick="item.targetEdit = true"
                  @keyup.enter="saveTarget(item)"
                  @keyup.esc="item.targetEdit = false"
                  style="width: 70px"
                  :readonly="!item.targetEdit"
                  class="pl-1 pr-1"
                  type="number"
                  :class="{'edit-input': item.targetEdit}"
                  v-model="item.target_expected"
                  :title="$t('system.double_click_to_edit')"
              />
            </template>
            <template v-slot:item.last_transaction_status="{ item }">
              <strong>{{ item.last_transaction_status }}</strong>
            </template>
            <template v-slot:item.is_new="{ item }">
              <v-chip :color="item.is_new ? 'success' : 'secondary'">{{ item.is_new ? $t('system.yes') : $t('system.no') }}</v-chip>
            </template>
            <template v-slot:item.visit_status="{ item }">
              <v-icon :color="item.visit_status ? 'green' : 'red'">fas fa-car</v-icon>
            </template>
            <template v-slot:item.account_priority_name="{ item }">
              <v-chip
                  :color="item.account_priority_color"
                  dark
              >
                {{ item.account_priority_name }}
              </v-chip>
            </template>
            <template v-slot:no-data>
              {{ $t('system.no_data') }}
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SimpleStaticFilters from '@/components/Filters/SimpleStaticFilters';

export default {
  name: 'ContractorsTarget',

  components: {
    SimpleStaticFilters,
  },

  data() {
    return {
      loading: true,
      formLoaded: false,
      filterParams: [
        {
          type: 'autocomplete',
          name: 'account_group',
          items: [],
          value: null,
        }, {
          type: 'autocomplete',
          name: 'account_name',
          items: [],
          value: null,
        },
      ],
      data: [],
      dataTableOptions: {
        page: 1,
        itemsPerPage: localStorage.getItem(`${this.$route.name.trim()}_ItemsPerPage`) ? parseInt(JSON.parse((localStorage.getItem(`${this.$route.name.trim()}_ItemsPerPage`))), 10) : 10,
        sortBy: ['account_name'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        totalItems: 5,
        footerProps: {
          itemsPerPageOptions: [10, 25, 50, 100],
          showFirstLastPage: true,
        },
      },
      headers: [
        {
          text: this.$t('system.account_name'),
          sortable: true,
          value: 'account_name',
        },
        {
          text: this.$t('system.target_expected'),
          sortable: true,
          value: 'target_expected',
        },
        {
          text: this.$t('system.taget_achieved'),
          sortable: true,
          value: 'taget_achieved',
        },
        {
          text: this.$t('system.last_month_sale'),
          sortable: true,
          value: 'last_month_sale',
        },
        {
          text: this.$t('system.last_transaction_status'),
          sortable: true,
          value: 'last_transaction_status',
        },
        {
          text: this.$t('system.is_new'),
          sortable: true,
          value: 'is_new',
        },
        {
          text: this.$t('system.visit_status'),
          sortable: true,
          value: 'visit_status',
        },
        {
          text: this.$t('system.account_priority_name'),
          sortable: true,
          value: 'account_priority_name',
        },
      ],
    };
  },

  watch: {
    dataTableOptions: {
      handler() {
        if (this.formLoaded) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },

  created() {
    this.fetchData();
    this.getAccounts();
    this.getAccountsGroups();
  },

  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const {data} = await axios.post(`/sk_visits/target/contractor`, this.prepareQuery());
        data.data.forEach((element) => {
          element.targetEdit = false;
        });
        this.data = data.data;
        this.dataTableOptions.totalItems = data.total;
        this.dataTableOptions.page = data.current_page;
        this.formLoaded = true;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
    },

    prepareQuery() {
      return {
        account_ids: this.filterParams.find((element) => element.name === 'account_name').value,
        account_groups_ids: this.filterParams.find((element) => element.name === 'account_group').value,
        per_page: this.dataTableOptions.itemsPerPage,
        page: this.dataTableOptions.page,
        order_by: this.dataTableOptions.sortBy[0] || 'id',
        direction: this.dataTableOptions.sortDesc[0] ? 'desc' : 'asc',
      };
    },

    async saveTarget(item) {
      if (!item.targetEdit) {
        item.targetEdit = true;
        return;
      }
      this.loading = true;
      try {
        await axios.put(`/sk_visits/target`, {
          account_id: item.account_id,
          target_value: item.target_expected,
        });
        item.targetEdit = false;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
    },

    setFilters(filterParams) {
      this.filterParams = filterParams;
      this.fetchData();
    },

    async getAccounts() {
      try {
        const {data} = await axios.get('/sk_visits/accounts');
        this.filterParams.forEach((element) => {
          if (element.name === 'account_name') {
            element.items = data;
          }
        });
      } catch (error) {
        console.log('error', error);
      }
    },

    async getAccountsGroups() {
      try {
        const {data} = await axios.get('/sk_visits/groups');
        this.filterParams.forEach((element) => {
          if (element.name === 'account_group') {
            element.items = data;
          }
        });
      } catch (error) {
        console.log('error', error);
      }
    },
  },
};
</script>

<style scoped>

.edit-input {
  border-bottom: 2px dotted #888;
  outline: none;
}

</style>
